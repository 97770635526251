import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Motion_Detection/INSTAR_Cloud/IFTTT/NavButtons';
import ForumBox from 'components/Motion_Detection/INSTAR_Cloud/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "How to create own IFTTT Applets with INSTAR?",
  "path": "/Motion_Detection/INSTAR_Cloud/IFTTT/User_Guide/How_to_create_own_Applets/",
  "dateChanged": "2020-10-21",
  "author": "Mike Polinowski",
  "excerpt": "On the IFTTT Platform, we provided a service named INSTAR. The INSTAR Applets provides a way for you to control some settings of your INSTAR Camera or INSTAR Cloud with IFTTT. You can connect INSTAR to Location and Date & Time triggers and receive Notifications and more. For example, you can connected the INSTAR Applets with the IFTTT Location service and to turn off the alarm when you arrive at home. You can also use the INSTAR Applets to build your own automation connecting them to other third-party Applets.",
  "image": "../MD_SearchThumb_Cloud_IFTTT.png",
  "social": "/images/Search/MD_SearchThumb_Cloud_IFTTT.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_MD-INSTAR-Cloud-IFTTT_white.webp",
  "chapter": "Motion Detection"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='How to create own IFTTT Applets with INSTAR?' dateChanged='2020-10-21' author='Mike Polinowski' tag='INSTAR IP Camera' description='On the IFTTT Platform, we provided a service named INSTAR. The INSTAR Applets provides a way for you to control some settings of your INSTAR Camera or INSTAR Cloud with IFTTT. You can connect INSTAR to Location and Date & Time triggers and receive Notifications and more. For example, you can connected the INSTAR Applets with the IFTTT Location service and to turn off the alarm when you arrive at home. You can also use the INSTAR Applets to build your own automation connecting them to other third-party Applets.' image='/images/Search/MD_SearchThumb_Cloud_IFTTT.png' twitter='/images/Search/MD_SearchThumb_Cloud_IFTTT.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Bewegungserkennung/INSTAR_Cloud/IFTTT/User_Guide/How_to_create_own_Applets/' locationFR='/fr/Motion_Detection/INSTAR_Cloud/IFTTT/User_Guide/How_to_create_own_Applets/' crumbLabel="IFTTT" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "how-to-create-own-applets-with-instar",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-create-own-applets-with-instar",
        "aria-label": "how to create own applets with instar permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How to create own Applets with INSTAR?`}</h2>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#how-to-create-own-applets-with-instar"
        }}>{`How to create own Applets with INSTAR?`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#1choose-a-trigger-from-service-instar"
            }}>{`1.Choose a trigger from Service INSTAR`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#2choose-a-action-from-service-instar"
            }}>{`2.Choose a action from Service INSTAR`}</a></li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <h3 {...{
      "id": "1choose-a-trigger-from-service-instar",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#1choose-a-trigger-from-service-instar",
        "aria-label": "1choose a trigger from service instar permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1.Choose a trigger from Service INSTAR`}</h3>
    <p><strong parentName="p">{`Example: If Cloud storage will expire, then send me an email`}</strong></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 01`}</strong>{`: Click the button "Create", then click button "Add" of "If This":`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "888px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ea1d45c5d198d40e6ae34a8d4a938c52/b04e4/create-trigger-01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "7.826086956521738%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAYAAABYBvyLAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAeUlEQVQI13WMQQsBQRhA9/8fXZSDRDmQXBRHf0FJKZFtpcwaO99X820Z88RVXr3je4VfrrkMZ1wnC8reGNnuyUB6JnLOP36IMWJmiCqtKlqdaUNDNKNw8xVld0Q1mHLq9JHN7hu90v9hCAERwdU16u/44wFxN/yj4Q3vUJXEjV52EAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ea1d45c5d198d40e6ae34a8d4a938c52/e4706/create-trigger-01.avif 230w", "/en/static/ea1d45c5d198d40e6ae34a8d4a938c52/d1af7/create-trigger-01.avif 460w", "/en/static/ea1d45c5d198d40e6ae34a8d4a938c52/dea7a/create-trigger-01.avif 888w"],
              "sizes": "(max-width: 888px) 100vw, 888px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ea1d45c5d198d40e6ae34a8d4a938c52/a0b58/create-trigger-01.webp 230w", "/en/static/ea1d45c5d198d40e6ae34a8d4a938c52/bc10c/create-trigger-01.webp 460w", "/en/static/ea1d45c5d198d40e6ae34a8d4a938c52/60055/create-trigger-01.webp 888w"],
              "sizes": "(max-width: 888px) 100vw, 888px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ea1d45c5d198d40e6ae34a8d4a938c52/81c8e/create-trigger-01.png 230w", "/en/static/ea1d45c5d198d40e6ae34a8d4a938c52/08a84/create-trigger-01.png 460w", "/en/static/ea1d45c5d198d40e6ae34a8d4a938c52/b04e4/create-trigger-01.png 888w"],
              "sizes": "(max-width: 888px) 100vw, 888px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ea1d45c5d198d40e6ae34a8d4a938c52/b04e4/create-trigger-01.png",
              "alt": "create-trigger-01.png",
              "title": "create-trigger-01.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "905px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6892242d72d6b6c2739db3aa43c79097/65d79/create-trigger-02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "45.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB1UlEQVQoz22R32uSURjH3z/G+0hYl/0FsnZRsQu30GVOQ61WUeq2WFCjEaQztSEaZV0sAhUEA52loqIwBRuW3uZNMG/En+j7ifOCMswHPjyH55zzfX5JAO12m3lr/WlRr9dpNBo0m00av3/RarX4eXYGyP+9/3t+zliWkTxuFzvbTl69duE+CuHyuPEcvcWxt4PJaMRqtXL/ng3bIweO7V0sd028OfTi93kJfvhM8Ms3PO8C7NvtlH98R9q4dYOVaxqWr6+zsrqGdnUZ866LTbMZy56X25bHbBr06IwWLCYjVpMBrd7I+poW/R0zOtMWN23PeaYzkPwYQpovfTAY8GDroVLZk6d2fD4/Xq+XQ7cLp9PJwcsXfHof5OvxMYtMGo/HCEajkRIQM5MkacaVpSXUajUajQaVSsXVy5fwH+xj2dAp78fjCVMNeTJBkmWZKcKGwyGVSoXT01NKpRKZTEYhl8tRLBYpFAqkT06URQm7+F8wa3kq2O12iUajJJNJarWaIpLNZqlWq5TLZfL5PLFYTEl2UXDW8nyFnU6HUChEOBwmkUiQTqeJx+NEIhEliSAQCJBKpRYLTiYTJTD1AjFP0Xq/36fX6yleLEucBeJezGyR4D8Uu015NhcSjwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6892242d72d6b6c2739db3aa43c79097/e4706/create-trigger-02.avif 230w", "/en/static/6892242d72d6b6c2739db3aa43c79097/d1af7/create-trigger-02.avif 460w", "/en/static/6892242d72d6b6c2739db3aa43c79097/8df31/create-trigger-02.avif 905w"],
              "sizes": "(max-width: 905px) 100vw, 905px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6892242d72d6b6c2739db3aa43c79097/a0b58/create-trigger-02.webp 230w", "/en/static/6892242d72d6b6c2739db3aa43c79097/bc10c/create-trigger-02.webp 460w", "/en/static/6892242d72d6b6c2739db3aa43c79097/4d060/create-trigger-02.webp 905w"],
              "sizes": "(max-width: 905px) 100vw, 905px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6892242d72d6b6c2739db3aa43c79097/81c8e/create-trigger-02.png 230w", "/en/static/6892242d72d6b6c2739db3aa43c79097/08a84/create-trigger-02.png 460w", "/en/static/6892242d72d6b6c2739db3aa43c79097/65d79/create-trigger-02.png 905w"],
              "sizes": "(max-width: 905px) 100vw, 905px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6892242d72d6b6c2739db3aa43c79097/65d79/create-trigger-02.png",
              "alt": "create-trigger-02.png",
              "title": "create-trigger-02.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 02`}</strong>{`: Type the "INSTAR" in the search box:`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "886px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/dc4da6621524a7a0a9afde0c601162b2/cc155/create-trigger-03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAAA60lEQVQoz21PwWqEMBTM35U99D/6Iz0s9LBfYunJSw/ibSnbg94EoTSwW7MiGlREk0zJ07RZ3YEhyXvzZl6YlBJZlmEYBlgopelM0xRBECCOYyRJQoyiCGEYIs9z0mitYYyhuxCC6myaJvR9T00LJ7BBnHN8c46fQhDPlwvV6rr+0zr9OI7kw+DBb1ZVhaIocA9Ga9KsTS2YK/gNu61N69oWZTfiq55Z9hOZKaU2P3LzbJPuBMv78Cnx+HYlvpzkHGhutT6Yb+Sn6WVq/9Hg6b0iPh+bxdBsNtsYrrfU5t/wIRDYvYq7husNfwHFwBhxy4yAgQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dc4da6621524a7a0a9afde0c601162b2/e4706/create-trigger-03.avif 230w", "/en/static/dc4da6621524a7a0a9afde0c601162b2/d1af7/create-trigger-03.avif 460w", "/en/static/dc4da6621524a7a0a9afde0c601162b2/d297b/create-trigger-03.avif 886w"],
              "sizes": "(max-width: 886px) 100vw, 886px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/dc4da6621524a7a0a9afde0c601162b2/a0b58/create-trigger-03.webp 230w", "/en/static/dc4da6621524a7a0a9afde0c601162b2/bc10c/create-trigger-03.webp 460w", "/en/static/dc4da6621524a7a0a9afde0c601162b2/b4685/create-trigger-03.webp 886w"],
              "sizes": "(max-width: 886px) 100vw, 886px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dc4da6621524a7a0a9afde0c601162b2/81c8e/create-trigger-03.png 230w", "/en/static/dc4da6621524a7a0a9afde0c601162b2/08a84/create-trigger-03.png 460w", "/en/static/dc4da6621524a7a0a9afde0c601162b2/cc155/create-trigger-03.png 886w"],
              "sizes": "(max-width: 886px) 100vw, 886px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/dc4da6621524a7a0a9afde0c601162b2/cc155/create-trigger-03.png",
              "alt": "create-trigger-03.png",
              "title": "create-trigger-03.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 03`}</strong>{`: You can see all triggers of the Service INSTAR:`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "878px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/50044702bf6901863e08f8e9c2298c34/94829/create-trigger-04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAACJUlEQVQ4y21TW08TQRidX6URUazFK9YLxiuiqKhEE6Mx0YCG+AuM/gttKUgpUHpTUQpBLPjsi4/u7O7srn1aeDjmzMzafejDZL7v7Hc7850VV5d9nPjo4khR4uisi8MzEpeWfDxZDTDRDPCgGeB6xcfT1RCPvwR4+CnAyVQ8z8CMxMVFD48+BxDnFjwcn3ORKUpkZ00gsXsNhTt1hds1pYPH6wov1kLcbygcs/FJQdqn512dIwaKMvXR3MQOFsxh96GSi0MFiQN5g6WLMYeD9BekZiLSXTLFbuespXJl2cer9UhPnbDoxneL86lGVnyIrE3udUifdEnzbKpgr8PmN1gwoZac/pTdlzcFciVTrC/fO472/g8OLi/5EGM1hVtVhbGquUdXfPzHasbn2yR+Gk/sxH/2NYTYdGK0ZYwfMsZPdxe/wj18d4y/5cTYsdiWxdoWJ0absdtyF5t/Yvz+uwdB3hx1eNHT+nu9EeFC2dMPTJw3l8IpOOlw2dMLmGpFWqOcknmk/m6nA0GHiWdKHs6XPUxvRHqz1CBpsMDkWohrFRPHZhT282+hbkKci+NS3rQ7EDerPiaaCsmk0+uRvhnEJtzuy1aEkYp527t1I3SKnEImNt5QWqt6Qv521FhuwdOBU61Qb43bpZj5B7DgaJVL8HVjCp8TkknOMqPo3253IGhk7J/BwnwvYoNWh0MlgzFxcK6ruclWiFPzpimFve+9oyf8B7x6DIhJFQOmAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/50044702bf6901863e08f8e9c2298c34/e4706/create-trigger-04.avif 230w", "/en/static/50044702bf6901863e08f8e9c2298c34/d1af7/create-trigger-04.avif 460w", "/en/static/50044702bf6901863e08f8e9c2298c34/b7a7f/create-trigger-04.avif 878w"],
              "sizes": "(max-width: 878px) 100vw, 878px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/50044702bf6901863e08f8e9c2298c34/a0b58/create-trigger-04.webp 230w", "/en/static/50044702bf6901863e08f8e9c2298c34/bc10c/create-trigger-04.webp 460w", "/en/static/50044702bf6901863e08f8e9c2298c34/6749f/create-trigger-04.webp 878w"],
              "sizes": "(max-width: 878px) 100vw, 878px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/50044702bf6901863e08f8e9c2298c34/81c8e/create-trigger-04.png 230w", "/en/static/50044702bf6901863e08f8e9c2298c34/08a84/create-trigger-04.png 460w", "/en/static/50044702bf6901863e08f8e9c2298c34/94829/create-trigger-04.png 878w"],
              "sizes": "(max-width: 878px) 100vw, 878px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/50044702bf6901863e08f8e9c2298c34/94829/create-trigger-04.png",
              "alt": "create-trigger-04.png",
              "title": "create-trigger-04.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 04`}</strong>{`: You can choose any of them to connect. (If you still don't authorize Service INSTAR,  you need to login with your INSTAR Cloud Account. ):`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "878px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6c514867ebd89a5bac6557b8f39fcea1/94829/create-trigger-05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABoUlEQVQoz31SSU4CQRTtUxknBJrYoICoicYYF05xvAPgSl14BmOERKChmwanMLj1ArrQC2hVdRFP8MyvBmlQWfz8+lVvqF+/tPUHF4bFES4z6BWOUJlhvspx2O7g6KmDg3YHO02p4vipg/2WxKwPTzFjMizVBVbvXGipmlAAvcIQqXjAqM2VwF5LYrcpQaabDYmthsR2U6oL6F3BHifucJCWFjSZz83LVE+XGCaLTLkTmPJUydv3367Hoc5IS+sdEChc7rdCzgSgNrLPX1iuC0WKDOAHORRa34kPOXuuCUdg49FF3PEER+F/BP0ulEPdCJgMc1WOtXsXMZurunfmx4eHBf2ORKQpJxyOhZpQmURpj2p6T1ob9ogb+gVp4gSmFhfrAqm6i6VbFyla1wTmq0L9guGv86egfzg6PYPJECx9IlD8UFk3mbf/z/spwf60umEJxC5fMXFiYjxdQPTcRvKiBuPMwlimgEC2COP6HSFL4he3zKDRJ+0Hg1F1sXD1gmD2BoF0DslzEysXNhKnJqbTeUQyecRzb5h1pIcf4HN8A+uLcjWctVRpAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6c514867ebd89a5bac6557b8f39fcea1/e4706/create-trigger-05.avif 230w", "/en/static/6c514867ebd89a5bac6557b8f39fcea1/d1af7/create-trigger-05.avif 460w", "/en/static/6c514867ebd89a5bac6557b8f39fcea1/b7a7f/create-trigger-05.avif 878w"],
              "sizes": "(max-width: 878px) 100vw, 878px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6c514867ebd89a5bac6557b8f39fcea1/a0b58/create-trigger-05.webp 230w", "/en/static/6c514867ebd89a5bac6557b8f39fcea1/bc10c/create-trigger-05.webp 460w", "/en/static/6c514867ebd89a5bac6557b8f39fcea1/6749f/create-trigger-05.webp 878w"],
              "sizes": "(max-width: 878px) 100vw, 878px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6c514867ebd89a5bac6557b8f39fcea1/81c8e/create-trigger-05.png 230w", "/en/static/6c514867ebd89a5bac6557b8f39fcea1/08a84/create-trigger-05.png 460w", "/en/static/6c514867ebd89a5bac6557b8f39fcea1/94829/create-trigger-05.png 878w"],
              "sizes": "(max-width: 878px) 100vw, 878px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6c514867ebd89a5bac6557b8f39fcea1/94829/create-trigger-05.png",
              "alt": "create-trigger-05.png",
              "title": "create-trigger-05.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 05`}</strong>{`: You need to set the relative settings, then click button "Create trigger":`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "877px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/833b3fc70470cd6b9b2b79b5f51560f7/4b446/create-trigger-07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.52173913043479%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB20lEQVQoz32ROW8TURSF5x8hSnDsEE9IcAqUiNgJhB0FSEEiCigQSCCWiqRCFgLRhA6vY+KxjRcpyCIgRAEUVOkiYN7ihT/woTfywMRYFEfv6b57zjvnXmtuS7FU00wVJUdygtktxc12j9s7fe5+6HOj3WNtu8ujj7+4s9Pn+tueX7/1rsd0UWLnJRM5wbyrOV3TWJN5ydGCJD54mCxIX3TeVZwoK46/Vj5xoaJZrGgSjsSYMD2BWMCbKkisoGAQiMaygmj272l+NuKRjPBTxAZvASceEraCQlAM7nYIK60uF+sdwr1/kNsvPFLQuDAxzWxPVbUfN+kq3+nJqiae358orGEN/xg0mLn6KCoSjmLaURwbwCwwnpNMjHBsjYphHKbKkuVGhws1xfmq5FxVMut4zJUEM8VwzP86FNh5QSQj2Wzv8u3zV/b2PJTsIkSH714X/VOw3tzlUEb6vf8I2kMLsH2HkrOux1rD41pDcbWuuPJGslJXrDY1yZLnpxjFtaIZQRhjWUH81Q8i6U8cfFghttEiut5kfKPF4ccNDjxwGX/2hVhOMTbENbBSribAgqtIVjRnyh6pp9vM3H/JcrrEpScOq8/LXE6XSNzbZOnFexZrPVKuIsw3+A3Wr35d4zyJBAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/833b3fc70470cd6b9b2b79b5f51560f7/e4706/create-trigger-07.avif 230w", "/en/static/833b3fc70470cd6b9b2b79b5f51560f7/d1af7/create-trigger-07.avif 460w", "/en/static/833b3fc70470cd6b9b2b79b5f51560f7/0a597/create-trigger-07.avif 877w"],
              "sizes": "(max-width: 877px) 100vw, 877px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/833b3fc70470cd6b9b2b79b5f51560f7/a0b58/create-trigger-07.webp 230w", "/en/static/833b3fc70470cd6b9b2b79b5f51560f7/bc10c/create-trigger-07.webp 460w", "/en/static/833b3fc70470cd6b9b2b79b5f51560f7/d7666/create-trigger-07.webp 877w"],
              "sizes": "(max-width: 877px) 100vw, 877px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/833b3fc70470cd6b9b2b79b5f51560f7/81c8e/create-trigger-07.png 230w", "/en/static/833b3fc70470cd6b9b2b79b5f51560f7/08a84/create-trigger-07.png 460w", "/en/static/833b3fc70470cd6b9b2b79b5f51560f7/4b446/create-trigger-07.png 877w"],
              "sizes": "(max-width: 877px) 100vw, 877px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/833b3fc70470cd6b9b2b79b5f51560f7/4b446/create-trigger-07.png",
              "alt": "create-trigger-07.png",
              "title": "create-trigger-07.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 06`}</strong>{`: Then you can click the button "Add" to add  "Action". Here we choose action 'Email' as an example:`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "885px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4f7eabf54d2403821b5daaf2e79578f2/efc66/create-trigger-08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "45.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAACCUlEQVQoz3WQ30uTcRTG909UF9Gt0E0/LkZSEBiZjKylrpTlRDZHbEI1lqN0N6arWYpCTRY2dRszS7qICJGiwhnLIovUtjGtXbTp5qj5A7W59/2EbyqadeBwvnw5z4fnOTKA2bk5/q70zzShUIhwOEw0GiUUDDI9PcXY+Bdm0+lt+4Io8GN2Dpmr4x43bI3ccThx+fpw3u/A5eniZlszJpMZk9nM1Wu1mCy1XG9qxVJj5lZLG53dbrweN4/6/Xgf9OG868DT2oJMVXQCRcFx8hXF5CvLKDmdh8ZwBY1Gjb66Bp3BTJW6GF1lJdqKci6VK6nSaqkoLUFdVsp5g4VCtZ4LF+tpUBYg4x81EIcnXzN4IwK9kwK+4AI9oQVpuoOLeIMLPJ5YYlmQwm7RSkBRFKWWbrcsoB9IonqaYL/nO7m+GId7Exx5mEDui7O3O0ZOVwx5zxQzi9m1+63qBYkhW4dJQFEkK4q8ScKLuIh/BgZT8OzbktSr79cJeJkAfxJ+Zf9oNjM2Iq87FIQsk8FRImMfeed/xUhgkE9vh/A/72ck4Gf0fYDPw0OMfxgmu7KyRbsRefPn/Pw8O3bu4pTyDIqThew7cBD5oVxUZ89xNO8YOXt20958G01JERORyJoJ4f/ATCaDwWDAaDRit9uxWq3odDqsdXU02mxcrjbS2e6gqaGeVCq1zeFvoc9IZtK85cUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4f7eabf54d2403821b5daaf2e79578f2/e4706/create-trigger-08.avif 230w", "/en/static/4f7eabf54d2403821b5daaf2e79578f2/d1af7/create-trigger-08.avif 460w", "/en/static/4f7eabf54d2403821b5daaf2e79578f2/01786/create-trigger-08.avif 885w"],
              "sizes": "(max-width: 885px) 100vw, 885px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4f7eabf54d2403821b5daaf2e79578f2/a0b58/create-trigger-08.webp 230w", "/en/static/4f7eabf54d2403821b5daaf2e79578f2/bc10c/create-trigger-08.webp 460w", "/en/static/4f7eabf54d2403821b5daaf2e79578f2/dad35/create-trigger-08.webp 885w"],
              "sizes": "(max-width: 885px) 100vw, 885px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4f7eabf54d2403821b5daaf2e79578f2/81c8e/create-trigger-08.png 230w", "/en/static/4f7eabf54d2403821b5daaf2e79578f2/08a84/create-trigger-08.png 460w", "/en/static/4f7eabf54d2403821b5daaf2e79578f2/efc66/create-trigger-08.png 885w"],
              "sizes": "(max-width: 885px) 100vw, 885px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4f7eabf54d2403821b5daaf2e79578f2/efc66/create-trigger-08.png",
              "alt": "create-trigger-08.png",
              "title": "create-trigger-08.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 07`}</strong>{`: Enter "Email" in searchbox and click the "Email":`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "871px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/657ef9ce039a5ad7547390dec317d216/9d5da/create-trigger-09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAABI0lEQVQoz3WQvUoDURCF90EsrS18BisbfQIRMZUERUHBwkIbK4NCwKBob2UhtoKNLNksRASLDYpLMBCFxMa4u3fmk72bNX94YBi4M/NxznV6vR6tVos4jkklIraHzSZu1cOr1ajXHwmCAN/3cV2XoNGwO6pqK1UURYRhiMOQ8mESx0RfXZJuh/+UJMkENJWTPwwPUmDnvcnnS4D8fKe+QQ3GGFQMYpK/RPlNfu+Mw0Sy/voBhTIUT5XCiVA4VjbPlJWScFvNdoyMmrHA8cjGfqHy8KzM7wn3T8rcrjK1pBxeKYsHSum6n8ToiEsbeRKYDb1AmVlTlo+yPr0qLOwrs0WlfDMADsMmgDZyf+GtrWxUsto6V7YvlJ1LZb2i3NUHkcf1C3vOCbgLsOH+AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/657ef9ce039a5ad7547390dec317d216/e4706/create-trigger-09.avif 230w", "/en/static/657ef9ce039a5ad7547390dec317d216/d1af7/create-trigger-09.avif 460w", "/en/static/657ef9ce039a5ad7547390dec317d216/ac357/create-trigger-09.avif 871w"],
              "sizes": "(max-width: 871px) 100vw, 871px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/657ef9ce039a5ad7547390dec317d216/a0b58/create-trigger-09.webp 230w", "/en/static/657ef9ce039a5ad7547390dec317d216/bc10c/create-trigger-09.webp 460w", "/en/static/657ef9ce039a5ad7547390dec317d216/81b74/create-trigger-09.webp 871w"],
              "sizes": "(max-width: 871px) 100vw, 871px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/657ef9ce039a5ad7547390dec317d216/81c8e/create-trigger-09.png 230w", "/en/static/657ef9ce039a5ad7547390dec317d216/08a84/create-trigger-09.png 460w", "/en/static/657ef9ce039a5ad7547390dec317d216/9d5da/create-trigger-09.png 871w"],
              "sizes": "(max-width: 871px) 100vw, 871px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/657ef9ce039a5ad7547390dec317d216/9d5da/create-trigger-09.png",
              "alt": "create-trigger-09.png",
              "title": "create-trigger-09.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 08`}</strong>{`: Then you can choose "Send me an email" and try to "Connect":`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "879px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fb67042489ec1c59c7b1b663943e6824/bcb8c/create-trigger-10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.56521739130435%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB4ElEQVQoz4WTy0tVURTG9z/RxErDQq/eG0YYEnFnNlKxgYgU+MjsvhIf5Auv+LhBPgbSpBxIFFZINQgiBwYa1ahJEDRomjiyUQO753jW98U+6x6996p14MderPWtb699zj6mblB4NS2suSOMJIXnU8LqhLB1VnhjQdeW+8KmGWHbnLD7gbB+XBhOqNZSFRdeGQIbJh2aC71g3SAYToBVcbA6AVbGwPpxsHEabJgCo8PQhimwOaNxKK56SygmtD7RIYcmFBdWxnQXO1nAuR7h2VvC8m5h5yLYNg+euam5ituFWosdoibl0NiJAoIJ7RrOyz9aA+dew984nCzUKuJPfLHXoYkkwf8RHQEv34VvFkkdrtv3aGu1fQ7N6U7hcZzqEJ5s1+Pbo5e0a+5oLVjR49CMPgGLGcutEytgZhWcfqHcWwXTK4Wag5hMP3Vp+I9n6xf4+D34/AP4bBNcXgd3fuMIZZBzafYELOaPq4KXn+Af99IAWNsP/wuvf9Vadi+vxwM9IXezLg1y/vm4nja9/aL3sXUWvJbR+/rxu9asUaAXaOy4Lo3YRBGB4avPYFmX/RvA0i7hievCjW8Hhvs9OcOsc8yEnmjTj21w8Y3ew6U18OE78OeO1gITn1zseR7/AmVA6mQ54kihAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fb67042489ec1c59c7b1b663943e6824/e4706/create-trigger-10.avif 230w", "/en/static/fb67042489ec1c59c7b1b663943e6824/d1af7/create-trigger-10.avif 460w", "/en/static/fb67042489ec1c59c7b1b663943e6824/401c6/create-trigger-10.avif 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fb67042489ec1c59c7b1b663943e6824/a0b58/create-trigger-10.webp 230w", "/en/static/fb67042489ec1c59c7b1b663943e6824/bc10c/create-trigger-10.webp 460w", "/en/static/fb67042489ec1c59c7b1b663943e6824/6f82a/create-trigger-10.webp 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fb67042489ec1c59c7b1b663943e6824/81c8e/create-trigger-10.png 230w", "/en/static/fb67042489ec1c59c7b1b663943e6824/08a84/create-trigger-10.png 460w", "/en/static/fb67042489ec1c59c7b1b663943e6824/bcb8c/create-trigger-10.png 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fb67042489ec1c59c7b1b663943e6824/bcb8c/create-trigger-10.png",
              "alt": "create-trigger-10.png",
              "title": "create-trigger-10.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "880px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ae5b69d9ce61e3fa875faa65ffd1cfe6/9c177/create-trigger-11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABlklEQVQoz4VSSy8DYRSdP8HGinhNRyOl0hb1JhJWIgQp4tUWpVSrnhGJjUfEzj+wEWli17/gEYmVpYWf8Z0j95tOOpVicXJyZ849c+69Y/iTYN8+6F0DzRhoxcG6ZcXBQzByBU5fgDMX4NgZOHUOzlyC7TtgQ1TRE4dG/YpiRxocOQGN5oRiYBu0VhXNmIiUFodS4PAxOHRkGwRTYP8B9IfatsDGqK0VSF9zAgxnQKMxZhs4Zm7T2iXFmkXF9Vtw8QasXlA6fUO0qHX0krJlAzSc2AIZ2c0CWcF1Djy9s8e0ftFKYlmf4cR2x/8JGV+PWead3Vdi6E6oStI5aN0EfRsoGBS1bv7VUDi8C3Zlwe6sYs8e2CX1rmJ3Frru3Qc7MyhMhP8MFVuToD8prDS3pxUDqWIt3JbE3wmLVy78EjGyKU7NZtSGFbefiUZf2rWesgk9hUVLo2/2k+b4E72Tr/RH3jSsiRd6xp/pm//S19Yh/tqhY+hdJc3Jd1b037N69JGh+TyDc3lWDedYOfDApukPW1PG8BvSBDdI1dzoNAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ae5b69d9ce61e3fa875faa65ffd1cfe6/e4706/create-trigger-11.avif 230w", "/en/static/ae5b69d9ce61e3fa875faa65ffd1cfe6/d1af7/create-trigger-11.avif 460w", "/en/static/ae5b69d9ce61e3fa875faa65ffd1cfe6/59bec/create-trigger-11.avif 880w"],
              "sizes": "(max-width: 880px) 100vw, 880px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ae5b69d9ce61e3fa875faa65ffd1cfe6/a0b58/create-trigger-11.webp 230w", "/en/static/ae5b69d9ce61e3fa875faa65ffd1cfe6/bc10c/create-trigger-11.webp 460w", "/en/static/ae5b69d9ce61e3fa875faa65ffd1cfe6/fd35f/create-trigger-11.webp 880w"],
              "sizes": "(max-width: 880px) 100vw, 880px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ae5b69d9ce61e3fa875faa65ffd1cfe6/81c8e/create-trigger-11.png 230w", "/en/static/ae5b69d9ce61e3fa875faa65ffd1cfe6/08a84/create-trigger-11.png 460w", "/en/static/ae5b69d9ce61e3fa875faa65ffd1cfe6/9c177/create-trigger-11.png 880w"],
              "sizes": "(max-width: 880px) 100vw, 880px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ae5b69d9ce61e3fa875faa65ffd1cfe6/9c177/create-trigger-11.png",
              "alt": "create-trigger-11.png",
              "title": "create-trigger-11.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 09`}</strong>{`: Enter the email address:`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "888px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cd94289825322f1d3bffc809633ba956/b04e4/create-trigger-12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABdUlEQVQoz32SvUtCYRTG7x8hLU6NLRVGk3PtLQVCjREV2cfSVFtDDUEQFdUSurlJCCKFKdxEcAiRKCJtMfwouLeLN733/OK90SfZgR/PgRee9xyeo62eCPMHwuy+MHcgzOzBTrzN+fkZqVSKi4sM6XSaRCJBMpkkHo+j6zqqXNdFRH6g9U67KIaWheEVl54pYWzD4fbmGl2/JJfLkc/nKRaLnmYyGSqVimeoDH6XFlgSdk+FYkUoPQhrUWFyW7Ask9dXm1arhWEYWJaFbduemqZJvV6n2Wx62mg0qFar1Go1tIGwcPcoPD4Lli2cXcHElkP5/o5SqcR9uUyn08FxHE8/erXuX2h9c8L4prBwKCwdCyPrwsSWi+O0abe/UEZqxW5G6k19pI2uC4OLQn/4XfvmhfCRy1/132QKz7BpCE+m8PzyTsMQzBZks1kikQixWIxoNEqhUPgMohteKD9n+EotGAzi9/sJBAL4fD5CoVDXU/l+Nm84+jiXeqY9JwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cd94289825322f1d3bffc809633ba956/e4706/create-trigger-12.avif 230w", "/en/static/cd94289825322f1d3bffc809633ba956/d1af7/create-trigger-12.avif 460w", "/en/static/cd94289825322f1d3bffc809633ba956/dea7a/create-trigger-12.avif 888w"],
              "sizes": "(max-width: 888px) 100vw, 888px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cd94289825322f1d3bffc809633ba956/a0b58/create-trigger-12.webp 230w", "/en/static/cd94289825322f1d3bffc809633ba956/bc10c/create-trigger-12.webp 460w", "/en/static/cd94289825322f1d3bffc809633ba956/60055/create-trigger-12.webp 888w"],
              "sizes": "(max-width: 888px) 100vw, 888px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cd94289825322f1d3bffc809633ba956/81c8e/create-trigger-12.png 230w", "/en/static/cd94289825322f1d3bffc809633ba956/08a84/create-trigger-12.png 460w", "/en/static/cd94289825322f1d3bffc809633ba956/b04e4/create-trigger-12.png 888w"],
              "sizes": "(max-width: 888px) 100vw, 888px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cd94289825322f1d3bffc809633ba956/b04e4/create-trigger-12.png",
              "alt": "create-trigger-12.png",
              "title": "create-trigger-12.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 10`}</strong>{`: Just click the button to "create action":`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "877px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/575bf73b7c0c75d0de7d12af0c602c8c/4b446/create-trigger-13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.565217391304344%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAABpklEQVQoz5WSz0sUcRjG/VM0I3Z2R9ODeOmwKEGQSOi0q25GpbvznaIIIvrlSh4UV7x5EBSCCAmvQcf+gQIVRbT2oKSb7OL+bpxZZ7/zidnDEgiye3jg4eV9P/A8vC1+4VKXLlF0z0uui3PUmEOH7tApnLpXoxU6DQdFuCi6rO3+z2i5ADQgMGFx+2WJaCLH8LssWtxTjtB0jvtzJsEneXwxWb+5FBh4DFciFq9XTU6OtviTOqRczFAspCmVsoCLnsjT+kCiGg0AFQEdk2fceG6jzTqE511GEzC6AMtf/rL+LYM2VeTqhCTQENAA9dEZN1/ZjC9KBqZtBt97qjAy583OCT4r44tWG4/cFrF4u2qST++xv7vJ0eE+mZMDfh/8olRIIxaaiOwB2+9ZvFg2OT5Osr27x89kklyhSFU6VGWF6HyBtmY7DD4tE56x0eIWQ1Mmw3GLpc+nrH9NEXqTbaJDXaIKl67xFK2DPwiENmtSw1v4725wbWiDroentZ9URINAvwH94e/09q0woH1CG1tDi6xx685Hevo+0D+2Q3fMxie4APwHsgYaTXoPj5wAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/575bf73b7c0c75d0de7d12af0c602c8c/e4706/create-trigger-13.avif 230w", "/en/static/575bf73b7c0c75d0de7d12af0c602c8c/d1af7/create-trigger-13.avif 460w", "/en/static/575bf73b7c0c75d0de7d12af0c602c8c/0a597/create-trigger-13.avif 877w"],
              "sizes": "(max-width: 877px) 100vw, 877px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/575bf73b7c0c75d0de7d12af0c602c8c/a0b58/create-trigger-13.webp 230w", "/en/static/575bf73b7c0c75d0de7d12af0c602c8c/bc10c/create-trigger-13.webp 460w", "/en/static/575bf73b7c0c75d0de7d12af0c602c8c/d7666/create-trigger-13.webp 877w"],
              "sizes": "(max-width: 877px) 100vw, 877px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/575bf73b7c0c75d0de7d12af0c602c8c/81c8e/create-trigger-13.png 230w", "/en/static/575bf73b7c0c75d0de7d12af0c602c8c/08a84/create-trigger-13.png 460w", "/en/static/575bf73b7c0c75d0de7d12af0c602c8c/4b446/create-trigger-13.png 877w"],
              "sizes": "(max-width: 877px) 100vw, 877px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/575bf73b7c0c75d0de7d12af0c602c8c/4b446/create-trigger-13.png",
              "alt": "create-trigger-13.png",
              "title": "create-trigger-13.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 11`}</strong>{`: Then you can see trigger and action are created, it means "If Cloud storage will expire, then send me an email":`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "886px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e383da01f2242e94d7607fed1e901184/cc155/create-trigger-14.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.434782608695656%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAACTElEQVQoz3WSXUhTcRjGT91028dVEXTdRRB0GTlKMbBCp66mGSbpRIlQswg1TezroiyzqXOWopSlolApZFSgqKk3jtKIws1AN6YJOj+Onp3/L7azLbf0hefi/Xie933+/CWAlZVVNguPx4PdbsfpdOF2u3E5Z3C7Z9kq5hcXkSw11ZSV3uRhZRXmhhaqas1U15sxN1spKy/nauE1iopLKLlVQemdB5SVFFFjsfK0pharpY7mrg80tLziceUjGu7fRUpOiCX6+DF0MafRxSWhPxNFclY+ekMSxou5pGXmkZZ4CqPBwHmjEVNSLKnGFAz6BM4mJ2LMzCMuxURqdjH34k8ibXb62AJ0TK7T8kul6adK48SSH8/GPdSPr2D5tkTvbzkwLcK4kq8ghAav0Jp1tgXO9cyia3cS1ebkROcs0V1zHG1zcaTVycHmGXI+zYfkfDQhVL+GFBQLwjcyv6bwY1FmcknGsapgc80xMjXNlOzFsSxjX5Zxyet+pUh+yLK2Rds57oD3o9D9Bd4NQcdnmZe9Hn/+ZhA6+2H4uzb7jxeyHBQUeFWtU9EqiK8QZD4RxBSrHL4Chy7D/nSVAxmCHQkC/W0R4okNimGW1YDlrw543SfoHlF4O6zy4qOH5z1/6BwQtPcrtPWpDEwEL4ywHPl+Qdvh4cvliJoasLvFG2607otpu4O8nFxMGZe4nl/AjYJCcrOyybqQztjIqCapqv99Ocnr9aIoSgjra2v+hm1wgO2ShCRJ7N29k317drEtkHc0NfpnZFkO4/rwF0ElL8JaM+OfAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e383da01f2242e94d7607fed1e901184/e4706/create-trigger-14.avif 230w", "/en/static/e383da01f2242e94d7607fed1e901184/d1af7/create-trigger-14.avif 460w", "/en/static/e383da01f2242e94d7607fed1e901184/d297b/create-trigger-14.avif 886w"],
              "sizes": "(max-width: 886px) 100vw, 886px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e383da01f2242e94d7607fed1e901184/a0b58/create-trigger-14.webp 230w", "/en/static/e383da01f2242e94d7607fed1e901184/bc10c/create-trigger-14.webp 460w", "/en/static/e383da01f2242e94d7607fed1e901184/b4685/create-trigger-14.webp 886w"],
              "sizes": "(max-width: 886px) 100vw, 886px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e383da01f2242e94d7607fed1e901184/81c8e/create-trigger-14.png 230w", "/en/static/e383da01f2242e94d7607fed1e901184/08a84/create-trigger-14.png 460w", "/en/static/e383da01f2242e94d7607fed1e901184/cc155/create-trigger-14.png 886w"],
              "sizes": "(max-width: 886px) 100vw, 886px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e383da01f2242e94d7607fed1e901184/cc155/create-trigger-14.png",
              "alt": "create-trigger-14.png",
              "title": "create-trigger-14.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 12`}</strong>{`: Finally, you can see your Applet be created and connected successfully:`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "879px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/74c18ad429f362f191c5e68f68c6d1a4/bcb8c/create-trigger-15.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.78260869565218%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAABeUlEQVQoz3WQu0sDQRCH96+yUCHRPIr4jLFUC18YX5iIEBsfIIKNgiCClZUoiXo+CkVjtJQQBO0FG8ndJYcoKkYun+zlguepAx87u/ObHzsjoukiXacG04kt1mLrrIysEtm4w6sY+FMq/l3NwufImxWNtgOdVpsmRSe0rzJx9oiQhdC+hi+ZJ5DKE0jmLSNfqmLiTao/zKoEbKr3zmOd8KGOCNoFnwspCu5VhDL3JFUabPO/kFrpI6oP/iq7Ko0plSZFI5o2WM49031SoP1Qp+NIJ+ga37kSiQg4vi8J2oKQojFxaTCWMRjPGFYukTvzu3qciPodFTd1O5XxoucFRi+KluHgWYHhdJHIcYHa7Tx/9UnEUNrAiRxz6LzIaOaJpVuThZsSM9l35nMl5rLvTF5966KuXon4NMv8xMQEXl7faAlHqKmtpzkcwevxUNPew+LFPWXT5HdfBYE7ymXrKH18MJ1I0NfXSzweY3iwn4GpWTavH6SI/+ILIMYh3b/Aog0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/74c18ad429f362f191c5e68f68c6d1a4/e4706/create-trigger-15.avif 230w", "/en/static/74c18ad429f362f191c5e68f68c6d1a4/d1af7/create-trigger-15.avif 460w", "/en/static/74c18ad429f362f191c5e68f68c6d1a4/401c6/create-trigger-15.avif 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/74c18ad429f362f191c5e68f68c6d1a4/a0b58/create-trigger-15.webp 230w", "/en/static/74c18ad429f362f191c5e68f68c6d1a4/bc10c/create-trigger-15.webp 460w", "/en/static/74c18ad429f362f191c5e68f68c6d1a4/6f82a/create-trigger-15.webp 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/74c18ad429f362f191c5e68f68c6d1a4/81c8e/create-trigger-15.png 230w", "/en/static/74c18ad429f362f191c5e68f68c6d1a4/08a84/create-trigger-15.png 460w", "/en/static/74c18ad429f362f191c5e68f68c6d1a4/bcb8c/create-trigger-15.png 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/74c18ad429f362f191c5e68f68c6d1a4/bcb8c/create-trigger-15.png",
              "alt": "create-trigger-15.png",
              "title": "create-trigger-15.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br /><br /><br />
    <h3 {...{
      "id": "2choose-a-action-from-service-instar",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#2choose-a-action-from-service-instar",
        "aria-label": "2choose a action from service instar permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2.Choose a action from Service INSTAR`}</h3>
    <p><strong parentName="p">{`Example: If Say a specific phrase, Then Turn off motion detection`}</strong></p>
    <br /><br />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 01`}</strong>{`: Click the button "Create", then click button "Add" of "If This". Here we choose action 'Alexa' as an example:`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "886px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d928a40ab159763372d6d04b9f99d0b0/cc155/create-action-01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "8.260869565217392%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAYAAABYBvyLAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAa0lEQVQI132MrQ7CQBAG+/4PgEKQGkhQvAGpITUg8PylaQiF7uduxV6H9DxMMmLMVM/Vlm654bGo6dc7CtPEP8wMSbyGAd0u6H5lNCMiqNSe+DQt7/0BHc/MqxxBzvmn7l5MKeEacVnpefgFT2WX3gJwPNUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d928a40ab159763372d6d04b9f99d0b0/e4706/create-action-01.avif 230w", "/en/static/d928a40ab159763372d6d04b9f99d0b0/d1af7/create-action-01.avif 460w", "/en/static/d928a40ab159763372d6d04b9f99d0b0/d297b/create-action-01.avif 886w"],
              "sizes": "(max-width: 886px) 100vw, 886px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d928a40ab159763372d6d04b9f99d0b0/a0b58/create-action-01.webp 230w", "/en/static/d928a40ab159763372d6d04b9f99d0b0/bc10c/create-action-01.webp 460w", "/en/static/d928a40ab159763372d6d04b9f99d0b0/b4685/create-action-01.webp 886w"],
              "sizes": "(max-width: 886px) 100vw, 886px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d928a40ab159763372d6d04b9f99d0b0/81c8e/create-action-01.png 230w", "/en/static/d928a40ab159763372d6d04b9f99d0b0/08a84/create-action-01.png 460w", "/en/static/d928a40ab159763372d6d04b9f99d0b0/cc155/create-action-01.png 886w"],
              "sizes": "(max-width: 886px) 100vw, 886px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d928a40ab159763372d6d04b9f99d0b0/cc155/create-action-01.png",
              "alt": "create-action-01.png",
              "title": "create-action-01.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "895px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a9b74ef1885191b0acadcfe35ffd21a9/fcbaf/create-action-02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "45.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAABuUlEQVQoz42PTW8SYRSF5x/wS3RjwpbwI4a2I63IlGIH+0G0ltZAoaABUVKNpmkXdYMx7kgQQxNJCN/ELmDDCliQisYm1pSNcWAeM6O0NSGxNznvXbznPLlHAPh+esqk6ff7tNttjo8/8/VLn16vx9nZYKL3x2DAz18qQmDTx12PwoONIJuRGP5wgK2nEfyxbRYVBVmeN/4XV9dZWvFy37tMJBonuBUgnnhOfP8dgUcxNhQPH96+QViQJWamRGx2N9ItJ3fmp3Gsx5mWJJy+BLeVe8iOWexON7JjjgWHnTnZjf2mhGfZi7LqY3YtyuOVNd7vvkDgCqNp+jO6ihVhOByiS/27dYWCQURRxOVyEQqFiEajbIfDLCkKzyJh9ncSfDw8NACqqp7nRqMRgqZpjPXnGg2z2YwgCJhMJiwWC1arFdFm48b1a8gzUzzxP2Tv1UvDr4MuM4SLWhfQTqdDs9mk0WhQrVapVCrU63U+HR1Rq9UolUp8Ozk5z/1T+TJwvDOZDMlk0gjn83my2SyFQoFyuUwul+P1wQGtVsvw6zX/C9SD6XTagOjQYrFogMbXpVIput3uxAt/A4/hT7TMeDsVAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a9b74ef1885191b0acadcfe35ffd21a9/e4706/create-action-02.avif 230w", "/en/static/a9b74ef1885191b0acadcfe35ffd21a9/d1af7/create-action-02.avif 460w", "/en/static/a9b74ef1885191b0acadcfe35ffd21a9/d176d/create-action-02.avif 895w"],
              "sizes": "(max-width: 895px) 100vw, 895px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a9b74ef1885191b0acadcfe35ffd21a9/a0b58/create-action-02.webp 230w", "/en/static/a9b74ef1885191b0acadcfe35ffd21a9/bc10c/create-action-02.webp 460w", "/en/static/a9b74ef1885191b0acadcfe35ffd21a9/dcb2d/create-action-02.webp 895w"],
              "sizes": "(max-width: 895px) 100vw, 895px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a9b74ef1885191b0acadcfe35ffd21a9/81c8e/create-action-02.png 230w", "/en/static/a9b74ef1885191b0acadcfe35ffd21a9/08a84/create-action-02.png 460w", "/en/static/a9b74ef1885191b0acadcfe35ffd21a9/fcbaf/create-action-02.png 895w"],
              "sizes": "(max-width: 895px) 100vw, 895px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a9b74ef1885191b0acadcfe35ffd21a9/fcbaf/create-action-02.png",
              "alt": "create-action-02.png",
              "title": "create-action-02.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 02`}</strong>{`: Type the "Alexa" in the search box:`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "907px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a63c048a30f7f08860fbc343cae8f374/142fb/create-action-03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABD0lEQVQoz3WQwUrDQBCG96W8exMET4JP4WN48qxHXyUQUpAGvaTQgtFYlFIP2ibZzabZzeaXSbLtNm1/GGbJ/Hwzf1iapjDGoGmatlOR8jwHzYqigJQSnHNYL4n8bie/UgosSRJorfeGpOlsBs/zMA5DRFGEIAjg+z6EEFuvPYIUxzGyLAOzAAtTUkKufoHG4JTcJMNDmN1kP65/llhMJ5DpmqxAU3eFGqbWLYgSVVV1EJ2KuTB3E2n0p3H3VuLhc4P79xIrZdolQ7971EFk6rp/P843uAoFbicSF2OOj6KLaQZH7EXewmjQv+ve8PRV4ea1A16/CMzlcaArduynu8CzUY7LUOD8meO73AFP6R9DUWi5rTnpVgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a63c048a30f7f08860fbc343cae8f374/e4706/create-action-03.avif 230w", "/en/static/a63c048a30f7f08860fbc343cae8f374/d1af7/create-action-03.avif 460w", "/en/static/a63c048a30f7f08860fbc343cae8f374/81d9e/create-action-03.avif 907w"],
              "sizes": "(max-width: 907px) 100vw, 907px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a63c048a30f7f08860fbc343cae8f374/a0b58/create-action-03.webp 230w", "/en/static/a63c048a30f7f08860fbc343cae8f374/bc10c/create-action-03.webp 460w", "/en/static/a63c048a30f7f08860fbc343cae8f374/468b0/create-action-03.webp 907w"],
              "sizes": "(max-width: 907px) 100vw, 907px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a63c048a30f7f08860fbc343cae8f374/81c8e/create-action-03.png 230w", "/en/static/a63c048a30f7f08860fbc343cae8f374/08a84/create-action-03.png 460w", "/en/static/a63c048a30f7f08860fbc343cae8f374/142fb/create-action-03.png 907w"],
              "sizes": "(max-width: 907px) 100vw, 907px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a63c048a30f7f08860fbc343cae8f374/142fb/create-action-03.png",
              "alt": "create-action-03.png",
              "title": "create-action-03.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 03`}</strong>{`: You can see all triggers of the Service Alexa and choose first trigger:`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "878px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/371400d23d42c55d04abeb5e7ce44e6e/94829/create-action-04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB+ElEQVQoz31Sy27TUBD1FxUh3oFCEeVZCgHSUljAAglBJB5N6YotEn8DRe2iQopEG7/Iw02bByA2qJu6Su0mXN/rYFccNPfaJEgRi6O5njmaOccz2qQdYKrMcdoIkDEYMjIGyOgMcw7Hgw2BmxWObIXjWVPgbo3jpD7EMwKc0hkmzAA3KhwafZyQhJQ0eB/XGY6VGHJVLnG0lHKHeexvU6ppgykqmcaUdNYMsNgOMd8Kcc4crg1joFgjq6NwJomX7UDaJPsX7X9ro6AdXmc4tDYaY2tq8rgZYDxRPPYfLvXS8o0QhS99FNp9zCd42Qqx0A6x0FJWXzQJQr4LSU1ykneayzcENL8fY/XHPj5u9ySK211Ye7+w5MZYdiMsuRGKnRjFvRjvdyKs7Kqc7sVY3o3wwY1kfLcTwYt+Q2t6ApOfOrhiM1yyGabWO3hV7yJbE7heDuTmaMOPNtX5XP2srD/eErhd5bhT5bhV5ThSYtjoHUBr+SFmLB+zToicE2La8PF6q4ecI3DP4bLJw7rA04bATI1jtqaakmWqzyWge96UDT2BrOFJRYRrJQ+L9S4mbI7zllJDW77vqOOnMyI1NOCCpb6JRzcrFX7bD/HcclFwfOQrHp6YLt62fyJbFdLqdJnLn06LIbXUnPJvvvflEHqTcrL+lR3gDxaVq+q+Ig4RAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/371400d23d42c55d04abeb5e7ce44e6e/e4706/create-action-04.avif 230w", "/en/static/371400d23d42c55d04abeb5e7ce44e6e/d1af7/create-action-04.avif 460w", "/en/static/371400d23d42c55d04abeb5e7ce44e6e/b7a7f/create-action-04.avif 878w"],
              "sizes": "(max-width: 878px) 100vw, 878px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/371400d23d42c55d04abeb5e7ce44e6e/a0b58/create-action-04.webp 230w", "/en/static/371400d23d42c55d04abeb5e7ce44e6e/bc10c/create-action-04.webp 460w", "/en/static/371400d23d42c55d04abeb5e7ce44e6e/6749f/create-action-04.webp 878w"],
              "sizes": "(max-width: 878px) 100vw, 878px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/371400d23d42c55d04abeb5e7ce44e6e/81c8e/create-action-04.png 230w", "/en/static/371400d23d42c55d04abeb5e7ce44e6e/08a84/create-action-04.png 460w", "/en/static/371400d23d42c55d04abeb5e7ce44e6e/94829/create-action-04.png 878w"],
              "sizes": "(max-width: 878px) 100vw, 878px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/371400d23d42c55d04abeb5e7ce44e6e/94829/create-action-04.png",
              "alt": "create-action-04.png",
              "title": "create-action-04.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 04`}</strong>{`: You need to set the relative settings, then click button "Create trigger". After finished it, then trigger be created successfully.(If you still don't authorize Service Alexa,  you need to login with your Alexa Account. ):`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "880px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d5e846f82bec651faa40e7e5afdd5219/9c177/create-action-05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB9ElEQVQoz3VRy27TQBT1b/QDkMojSZsUNaE8KzUIqSAawqIPJLKpIhaIBYglP1CxRSC67AKxQQgBAkIbe2w3ck15tESCIGBRIgoO6szYLA+6Yzs4KSyOZu69c8895442vipQbEhkmcCwwRXm3wW4tBHgajPA5c0AF177uNYMUI3y15u/UXnjI2Nw1TdkcBQsgel1H9qoKZA3BbIsLNJ51BaIB9H9xKrAlCtVfDiKKU9vc0wo0P2YLaCRoiHjLxmdNDltcBzQucrNvw0wu+4jpfNuLdPXQxwkTAuTod34HoMmjzCBsuuj5Mpd9XBFose2liSL1Q5HCsjCpCMVTjsSZ9ckjlii60j19e2xR+FBJnDcDvdzyJbIWxIF28eYLVU8akmMmOFJzeHuxf8JySJ9Etkssg5mGx7OmB5OMg+nzA4mmIcJw0NB/xVZ/ZfCyGKSdM/yDm44W/j0sYXGxgd8/rmDrx2BL57Atw7HFXsLgyvh212E8bfnEp9AxUl9G9XGNirWd1TMNqb1NmaMtoqL9R9KYbKPxIwR4X6dI4l9dY4U85G+v4mBhYfI3n6B3J0a8osvkb71DAM3HyHzoIWUIXv69tZDxdo5h6Mf512JuSfvUbr7GBeXagrVeyuoLNUwtfgUc89bKLuy+768xlFyOGZeCfwB4I2JXpyY7cEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d5e846f82bec651faa40e7e5afdd5219/e4706/create-action-05.avif 230w", "/en/static/d5e846f82bec651faa40e7e5afdd5219/d1af7/create-action-05.avif 460w", "/en/static/d5e846f82bec651faa40e7e5afdd5219/59bec/create-action-05.avif 880w"],
              "sizes": "(max-width: 880px) 100vw, 880px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d5e846f82bec651faa40e7e5afdd5219/a0b58/create-action-05.webp 230w", "/en/static/d5e846f82bec651faa40e7e5afdd5219/bc10c/create-action-05.webp 460w", "/en/static/d5e846f82bec651faa40e7e5afdd5219/fd35f/create-action-05.webp 880w"],
              "sizes": "(max-width: 880px) 100vw, 880px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d5e846f82bec651faa40e7e5afdd5219/81c8e/create-action-05.png 230w", "/en/static/d5e846f82bec651faa40e7e5afdd5219/08a84/create-action-05.png 460w", "/en/static/d5e846f82bec651faa40e7e5afdd5219/9c177/create-action-05.png 880w"],
              "sizes": "(max-width: 880px) 100vw, 880px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d5e846f82bec651faa40e7e5afdd5219/9c177/create-action-05.png",
              "alt": "create-action-05.png",
              "title": "create-action-05.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 05`}</strong>{`: Then you can click the button "Add" to add  "Action":`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "879px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bdcff3a90dfe111a95e88e89e672ca3a/bcb8c/create-action-06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.30434782608696%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAACGUlEQVQoz4WQz0sUcRjG55/oVqQEJeqibmL5m6zItpayICqSglgC91LCHupQSGQQBHXt2KEfh1Zwgy5BaNilDC1Td2dmZ2fHWTPFnZ39ye7MfGKntMtKLzw8L9/3fZ7vwysA5PN5alX13TAMcrkcZiZDNmvuuFutQrGIsPB9genpaWKiSELTkRSFuCoSTypEJid49fIF4XCYN+EJJt++IxKJoKoqkiSjKAora5us6DrzXz8zMzWFcKB+F3V797C7vpk6zyGaPftoPDLIfm8LLX2naOk+Tke7l9bD/bR39dPd1kCjt5OmZg8HvW30DV2nsWuQjh4/I60NCLFYFFmSiIlxZFlCT0bRfxVIJRLoP9NIssrS3CzL3+ZYnJtFk2JocRlVlpBkGaXKCY3Uusl6XESodYsNC9QKaGWIGkV+pIssGSWWjRK6DSsWGDvcUbBtmy1Ytg2Ozd3lApe/mHR+zNA1k6XnU85lz5RJ04c09e/T3JjPAjYVa0vvuLyd0HEcbMdx+9erFcblMg8Vy8VYtMB9scSYXHFxR7J4nrLc3arG+atzE/LP0YU7ssrYeZP8xhoVM816UiEZXcQy01Qym2RXNZxCbjsItQyrA8v68+vQufP4z5zlwsVLdPf2cuKkj+Gr1+gfOMrpYwM8GrvH7Vs3t3XO/wyDwSB+v59QKEQgEMDn8zE6OspIMEhg+ArPnj7h8fiDmoa/Aezrn9XEXJgiAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bdcff3a90dfe111a95e88e89e672ca3a/e4706/create-action-06.avif 230w", "/en/static/bdcff3a90dfe111a95e88e89e672ca3a/d1af7/create-action-06.avif 460w", "/en/static/bdcff3a90dfe111a95e88e89e672ca3a/401c6/create-action-06.avif 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bdcff3a90dfe111a95e88e89e672ca3a/a0b58/create-action-06.webp 230w", "/en/static/bdcff3a90dfe111a95e88e89e672ca3a/bc10c/create-action-06.webp 460w", "/en/static/bdcff3a90dfe111a95e88e89e672ca3a/6f82a/create-action-06.webp 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bdcff3a90dfe111a95e88e89e672ca3a/81c8e/create-action-06.png 230w", "/en/static/bdcff3a90dfe111a95e88e89e672ca3a/08a84/create-action-06.png 460w", "/en/static/bdcff3a90dfe111a95e88e89e672ca3a/bcb8c/create-action-06.png 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bdcff3a90dfe111a95e88e89e672ca3a/bcb8c/create-action-06.png",
              "alt": "create-action-06.png",
              "title": "create-action-06.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 06`}</strong>{`: Enter "INSTAR" in searchbox and click the "INSTAR":`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "890px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/51c8ebd1c1433066acfcf99635ccca94/4ef49/create-action-07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "36.08695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAAA+klEQVQoz22PvUrEQBSF591sbS3s7IUFX8HCR/AZFCwsRLAxghBEGIJN0CZC2A3iuhHJIpPMzyeTPzNhD1zOMPfcc88VVVWRZRnGGDyccx0DcRwTRRFSSpIkad9pmo66oTyKoiDPc4Q3UkoFAmc0rlaYpqHWmrrRLTdat4sDbV/W2rYEPYZNxjnK1ZL3RPJbbobuyMOgD+F5OushguievcBorFJgDY9LxeXblovXLfJDdaazc6ceImh0Xf73wfF9yf71mr2rT06ffto/O9HMTcOTh4T92+Pk4ZtFVHJ0t+HseWI4SziezA5MxT7V4e0XBzdrzl+qwHAX/gAITRoaepIxQQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/51c8ebd1c1433066acfcf99635ccca94/e4706/create-action-07.avif 230w", "/en/static/51c8ebd1c1433066acfcf99635ccca94/d1af7/create-action-07.avif 460w", "/en/static/51c8ebd1c1433066acfcf99635ccca94/ada94/create-action-07.avif 890w"],
              "sizes": "(max-width: 890px) 100vw, 890px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/51c8ebd1c1433066acfcf99635ccca94/a0b58/create-action-07.webp 230w", "/en/static/51c8ebd1c1433066acfcf99635ccca94/bc10c/create-action-07.webp 460w", "/en/static/51c8ebd1c1433066acfcf99635ccca94/8d1ba/create-action-07.webp 890w"],
              "sizes": "(max-width: 890px) 100vw, 890px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/51c8ebd1c1433066acfcf99635ccca94/81c8e/create-action-07.png 230w", "/en/static/51c8ebd1c1433066acfcf99635ccca94/08a84/create-action-07.png 460w", "/en/static/51c8ebd1c1433066acfcf99635ccca94/4ef49/create-action-07.png 890w"],
              "sizes": "(max-width: 890px) 100vw, 890px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/51c8ebd1c1433066acfcf99635ccca94/4ef49/create-action-07.png",
              "alt": "create-action-07.png",
              "title": "create-action-07.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 07`}</strong>{`: Then you can choose "Turn off motion detection" and try to "Connect":`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "881px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d9949dba9d5ac0b3218393e9c075fbea/96658/create-action-08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACBklEQVQoz32S3WvTUBjGz9/lKlu7Wbc5Z53zY9ahICLqGCoIGzLFi+Ef4J14r2xLlzVNmnat1eGcAxEEEYTVkqm0no+mkYzIpjxyTtrSdbKLH3lzznPevM/JQ8ZMjsmcwMAyQ1SjiKXCZ3+K4WbJxdQrF9eLdVwt1DH92sW1Yl3ttbSSXo3iRDrsQ4Z0hrjOlCjWxajBccrgGFphGNQZEhmOkwY/oJPNjy0zpSOykF+IaqwJbdO7RHF0kSKZE7hoC1XLtWhbT/e56tMoiJyuc/z/cavkKvuHaVqQ43pYtCz3p/bbl/VZi+OcJQ5ouq9JvhNpoWeRItKkp0lkibXp05iyE+nSRbo4skBBVrd/I1MJFGYlgO0EsCs7MLZ8ZLZ8WGUf2a8+rOa+2aEzO85J8k4AwoO/eLTpKWbWPcy+cfFss4q5Ug3zazXczlcxX3Tw5EMDM29D3ex6A08/+Xj8/hfmNjw8fOfh/oaH2s4fkM9iFxeyAklbYDjNMaL/xJ18FedzLiZsgYEVgQn9O24UOE6b4d+OpajK5+V8HeMWV3csc/yR7YJ8cffU4hmTq4bjBsW9QhUJy8WYKTBo1HHF+KEaygzKw/K+7q41cCknwmymw2zK4Ui5saeajaoAcySzDA9Wv2H4hYPEgoP4821M6WVMv+SI66FOJkPalNPKwI+kuWosh/sHXFyW/7QEtykAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d9949dba9d5ac0b3218393e9c075fbea/e4706/create-action-08.avif 230w", "/en/static/d9949dba9d5ac0b3218393e9c075fbea/d1af7/create-action-08.avif 460w", "/en/static/d9949dba9d5ac0b3218393e9c075fbea/5334c/create-action-08.avif 881w"],
              "sizes": "(max-width: 881px) 100vw, 881px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d9949dba9d5ac0b3218393e9c075fbea/a0b58/create-action-08.webp 230w", "/en/static/d9949dba9d5ac0b3218393e9c075fbea/bc10c/create-action-08.webp 460w", "/en/static/d9949dba9d5ac0b3218393e9c075fbea/8e8eb/create-action-08.webp 881w"],
              "sizes": "(max-width: 881px) 100vw, 881px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d9949dba9d5ac0b3218393e9c075fbea/81c8e/create-action-08.png 230w", "/en/static/d9949dba9d5ac0b3218393e9c075fbea/08a84/create-action-08.png 460w", "/en/static/d9949dba9d5ac0b3218393e9c075fbea/96658/create-action-08.png 881w"],
              "sizes": "(max-width: 881px) 100vw, 881px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d9949dba9d5ac0b3218393e9c075fbea/96658/create-action-08.png",
              "alt": "create-action-08.png",
              "title": "create-action-08.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 08`}</strong>{`: You need to set the relative settings, then click button "Create action":`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "881px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0b9cf1aaef901657a33801f2384432b1/96658/create-action-09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABw0lEQVQoz4WSz0sbQRzF8wfpveKPZHeTtEas2mIsIiISKQitl4KCt9aTB4XaXsSDPy4WM7vuZhulEH/f9WxB1HQTmZnNKtZeX5nZbMyqmMOD73f37WfeYyfSa3O823Kh6QwKoVJiHitU8HHfw8S+h0yhgg97Ht7vVPDp8Fq+C7yqzhDNUnRbHIPbLiIpk6HLYhKi1kkY3v7keGNzvNr0Pa9z/t6d4yGvACcMBsGKiEGcoIaAVD7ryFK0Z6k8OZ135R6tSn0QQEBjhCKSNFgtelAj2GPVeXzXrythhIYg9X6hEFAkC4yaTjGQ5xjY4kjn/foiqdiFTyH3/nqFE+r3cwdh0DZdvDQ5Oi1XKmW5SJouolWY8gD2OGFd1T7dQSZXRMZ2MGo7SOtF9Ot/0E8u0Zl1EKv56fNAzWBo+XGFz4Uizs4vQb1b8Js7XHl/Ub7+B0o5pn9d4MUGR1zACG2UUPwthh5SwojlYCRXxqhdxpBZwqBZwrBVQoqUawmVRpWDi51cPUXb/B7UhQMkvh8h/u0Qsa8HaJ3bRXLtN1SD+981Sigvqc4QXzxG09QKlJl1pGaJVPTLOponl5FYOoFmuE8C/wOwfnm9MHapvQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0b9cf1aaef901657a33801f2384432b1/e4706/create-action-09.avif 230w", "/en/static/0b9cf1aaef901657a33801f2384432b1/d1af7/create-action-09.avif 460w", "/en/static/0b9cf1aaef901657a33801f2384432b1/5334c/create-action-09.avif 881w"],
              "sizes": "(max-width: 881px) 100vw, 881px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0b9cf1aaef901657a33801f2384432b1/a0b58/create-action-09.webp 230w", "/en/static/0b9cf1aaef901657a33801f2384432b1/bc10c/create-action-09.webp 460w", "/en/static/0b9cf1aaef901657a33801f2384432b1/8e8eb/create-action-09.webp 881w"],
              "sizes": "(max-width: 881px) 100vw, 881px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0b9cf1aaef901657a33801f2384432b1/81c8e/create-action-09.png 230w", "/en/static/0b9cf1aaef901657a33801f2384432b1/08a84/create-action-09.png 460w", "/en/static/0b9cf1aaef901657a33801f2384432b1/96658/create-action-09.png 881w"],
              "sizes": "(max-width: 881px) 100vw, 881px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0b9cf1aaef901657a33801f2384432b1/96658/create-action-09.png",
              "alt": "create-action-09.png",
              "title": "create-action-09.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 09`}</strong>{`: Then you can see trigger and action are created, it means "If Say a specific phrase, Then Turn off motion detection":`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "891px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a2354b39c0747e155bd70058d934f6af/b7877/create-action-10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.043478260869556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABw0lEQVQoz42SzUtUURiHL9I/UCS0bhOY0U6iNkEtqo1SGFQ0tVGKWtauNgZln1CL/ofcGCEaNChR06AWWgsRcioqJuPO/Tj3Y+bczrnniTvOxMxI6tmc8/L78bwf57XoOMaYtlhFAVJ4JIFP1Xczx4Z+q1VoiiuR5pPQLAjNfKXGrB0zZ1eZ/R3xUWgWhWY51OiGvxVqdWZKjeHaUpXBDxF9bwMOFEIOFWMOFmP2vg7ZMyPYPS04XAwJ1CbApvCsnPDoi+RBSXK3JLm1HDGyFHCnJLm9UmPkc40nXyUy3QBIC1BpQ5SkRIlGpWDbDt9/lOvT0yolTQ2mDbZJy48XAkbnBdffeFydcTk35TCcdxnKO5yZrDDwwubKtEv0J91ay6NzghsFn4fvBTcLHhdfVriUd7icd8hN2QxOrAHjrQKlMvhJilAggVUv4NsvG5VpBqraIHXmX786VhPUPovGHYckvotYLeOVfyJ9FxWKbDD/fP/dw2YmpXX9PTE2xrauLnbu2M6+nh729/ayq7sby7J4ev9e4/N0W2frKzQGna5lX3xXoP/4MU4P9DOUO8/whRxnT53kxNEjvHo+Xvdk3s4K/wLFliOdGYskuQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a2354b39c0747e155bd70058d934f6af/e4706/create-action-10.avif 230w", "/en/static/a2354b39c0747e155bd70058d934f6af/d1af7/create-action-10.avif 460w", "/en/static/a2354b39c0747e155bd70058d934f6af/8c2dc/create-action-10.avif 891w"],
              "sizes": "(max-width: 891px) 100vw, 891px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a2354b39c0747e155bd70058d934f6af/a0b58/create-action-10.webp 230w", "/en/static/a2354b39c0747e155bd70058d934f6af/bc10c/create-action-10.webp 460w", "/en/static/a2354b39c0747e155bd70058d934f6af/3b50c/create-action-10.webp 891w"],
              "sizes": "(max-width: 891px) 100vw, 891px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a2354b39c0747e155bd70058d934f6af/81c8e/create-action-10.png 230w", "/en/static/a2354b39c0747e155bd70058d934f6af/08a84/create-action-10.png 460w", "/en/static/a2354b39c0747e155bd70058d934f6af/b7877/create-action-10.png 891w"],
              "sizes": "(max-width: 891px) 100vw, 891px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a2354b39c0747e155bd70058d934f6af/b7877/create-action-10.png",
              "alt": "create-action-10.png",
              "title": "create-action-10.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 10`}</strong>{`: Finally, you can see your Applet be created and connected successfully:`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "880px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cbe86b9f84972f33e5e946ba4c7f54f6/9c177/create-action-11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAACIklEQVQoz4WQS08TURTHR2N8fQrBla8FK8ujRsAEUTduXbjRRE2IQWNMXWkMuPcDmBQ1fgUXmtg2w5SHEagMDyUhpC3QamnLvTOFls7P3DvT8giJN/nlnDvn/z/n3DGujUluf3foGJW0m0LHkCkIW5IHKZf7My53p13uzbg8+unyMOXStUeruGwKepKSOz8cjCuWpDcpg6IvUs3U9xvjPtfHJf3jkpsBqtY1KpsNlU/d+8YkhpoUCqa1m36xf8Khy3I4FxOcj8uA3fxSwqEtsbtA41WqqdFxYFLIlDy3Hd4uOQwtSIbmBcMLkjeLDq/tsmbYLvM4JQgpT3MZoTc3wuqJwTPDo4LOZIXPSznS8ykW52zsmSns6Sl+zdkU8zmKf/+wVchh5SSdlqM94eAXdCclxsWYQBP344WYoNcS3EqWuWqW6Db9qOibqNA3WaF/skLPmKO12hf3fW0JgfExs80uVR2j6SrRbJ33q/uJZmq8W9nS+Uimxoc9HpV/ylYxOOxUXcR6ls21jM96lvJqGplbA6/G6u8FaqXCoVajWvfYhwfO1jaF8ib5jSL5QpH8RolcYUPfy06F5XSW4qZkR83e5/UwPOAgdc/jf6eha3qCaNSDiyoqdoJmK/YsL54+4dnAAK8iEV5GIjqPDA6ybM9qzU7g0TQaNjdT0zyPWr2uxVbsG8dPncY4cpSW1rOcaWnV+bETJ0l8/aI1StvwNfr8A5E5H9xRbKJiAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cbe86b9f84972f33e5e946ba4c7f54f6/e4706/create-action-11.avif 230w", "/en/static/cbe86b9f84972f33e5e946ba4c7f54f6/d1af7/create-action-11.avif 460w", "/en/static/cbe86b9f84972f33e5e946ba4c7f54f6/59bec/create-action-11.avif 880w"],
              "sizes": "(max-width: 880px) 100vw, 880px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cbe86b9f84972f33e5e946ba4c7f54f6/a0b58/create-action-11.webp 230w", "/en/static/cbe86b9f84972f33e5e946ba4c7f54f6/bc10c/create-action-11.webp 460w", "/en/static/cbe86b9f84972f33e5e946ba4c7f54f6/fd35f/create-action-11.webp 880w"],
              "sizes": "(max-width: 880px) 100vw, 880px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cbe86b9f84972f33e5e946ba4c7f54f6/81c8e/create-action-11.png 230w", "/en/static/cbe86b9f84972f33e5e946ba4c7f54f6/08a84/create-action-11.png 460w", "/en/static/cbe86b9f84972f33e5e946ba4c7f54f6/9c177/create-action-11.png 880w"],
              "sizes": "(max-width: 880px) 100vw, 880px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cbe86b9f84972f33e5e946ba4c7f54f6/9c177/create-action-11.png",
              "alt": "create-action-11.png",
              "title": "create-action-11.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "881px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6669175396272b86f6f4f12e4ff751e2/96658/create-action-12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAByElEQVQoz3WSXWsTQRSGBwT/gXd+XSt4U9M2rTWaqLRq/SyhFQW/glopFQwVCoq/QNArFe8Uf4BSFC9EafYjm7INFktFpCIiWBG7O7tpl5o8Mptt2Sx14GEOZ+Z9zznDiMGPKww/fMvj4gMe3bzPjXsvSVeWyWgue3XZQo8uyZmS3rLksOVxqCzpK0uypscxc5G7M38QvZZP9s0P+ie+0j8xz4HX3+nQJB0ll84EKtelSfYodEm31kTFqsj5aYno0SQpo0abuRTSbtZCcXdkqkhrco31iqi7GUNycspHqGQ6RmfUxblpnyHbD0dKRcKkeZzdky7HKx5itWqygyHb40LVZ9D2OGP7nJryyOitd+Pa9lLMME5XNOrwjM/tuSWufmjuxdkaRyxvbYKkRhmeUIYqSKJGHKi4XLQd8hWHAcvhoL7IrvcOqZJkPU3bpMtRy0NkDckqOaP5LfYbksJcg7FvcPkznJ6F/CfIV5fpM53wPBfXmZJ9hgyfR/wK6sRZCOo4QZ0nT59ztnCNkbFxRm+Nc+X6CKPPXvFufgEnWOFn0CCp/R3UEbSsRgQUC5fYKAQ7t25mx7YtbNog2F64w4vqF+Av/1v/AOLeYS8TtRmxAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6669175396272b86f6f4f12e4ff751e2/e4706/create-action-12.avif 230w", "/en/static/6669175396272b86f6f4f12e4ff751e2/d1af7/create-action-12.avif 460w", "/en/static/6669175396272b86f6f4f12e4ff751e2/5334c/create-action-12.avif 881w"],
              "sizes": "(max-width: 881px) 100vw, 881px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6669175396272b86f6f4f12e4ff751e2/a0b58/create-action-12.webp 230w", "/en/static/6669175396272b86f6f4f12e4ff751e2/bc10c/create-action-12.webp 460w", "/en/static/6669175396272b86f6f4f12e4ff751e2/8e8eb/create-action-12.webp 881w"],
              "sizes": "(max-width: 881px) 100vw, 881px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6669175396272b86f6f4f12e4ff751e2/81c8e/create-action-12.png 230w", "/en/static/6669175396272b86f6f4f12e4ff751e2/08a84/create-action-12.png 460w", "/en/static/6669175396272b86f6f4f12e4ff751e2/96658/create-action-12.png 881w"],
              "sizes": "(max-width: 881px) 100vw, 881px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6669175396272b86f6f4f12e4ff751e2/96658/create-action-12.png",
              "alt": "create-action-12.png",
              "title": "create-action-12.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      